
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FriendRequestClient, ReportClient, UserClient } from '@/services/Services';
import ReportCustomConfirm from '@/components/reportCustomConfirm.vue';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import * as OM from '@/model';
import * as VM from '@/viewModel';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class RequestChatSettingsModal extends Vue {

    @Prop() friendRequestIdentifier: string;
    @Prop() chatName: string;
    @Prop({
        default: "/chats"
    }) buttonUrl: string;

    deleteFriendRequest() {
        FriendRequestClient.deleteFriendRequest(this.friendRequestIdentifier)
        .then(x => {
            for(var i = 0; i < this.$opModal.modals().length; i++) {
                this.$opModal.closeLast();
            }

            var text = LocalizationServices.getLocalizedValue("app_richiestaDiAmiciziaRimossaConSuccesso", "Richiesta di amicizia rimossa con successo!");
            this.$opModal.show(ModalSuccessError, {
                message: text,
                buttonUrl: this.buttonUrl
            })
        })
    }

}

