import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "widePage hasHeader" }
const _hoisted_2 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "writerContainer",
  style: {"height":"82px"}
}
const _hoisted_5 = { class: "d-flex h-100 align-items-center w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mini_spinner = _resolveComponent("mini-spinner")!
  const _component_chat_header = _resolveComponent("chat-header")!
  const _component_chat_component = _resolveComponent("chat-component")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_mini_spinner)
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode(_component_chat_header, {
            hasUserInfo: _ctx.chatVm,
            hasOptions: _ctx.chatVm,
            onOpenDetail: _ctx.openDetail,
            onSettingsModal: _ctx.settingsModal,
            userIdentifier: _ctx.chatVm.userIdentifier,
            userName: _ctx.chatVm.name
          }, null, 8, ["hasUserInfo", "hasOptions", "onOpenDetail", "onSettingsModal", "userIdentifier", "userName"]),
          _createVNode(_component_chat_component, {
            "chat-room": _ctx.chatVm,
            "container-adjustment": 3,
            "container-adjustment-oko": 83
          }, null, 8, ["chat-room"]),
          (_ctx.chatVm && _ctx.chatVm.toUser == _ctx.$store.state.user.identifier)
            ? (_openBlock(), _createBlock("div", _hoisted_4, [
                _createVNode("div", _hoisted_5, [
                  _createVNode("button", {
                    type: "button",
                    class: "genericButton bgPurple w-50 me-1",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deny && _ctx.deny(...args)))
                  }, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "app_rifiuta",
                      text: "Rifiuta",
                      class: "fs_14 white"
                    })
                  ]),
                  _createVNode("button", {
                    type: "button",
                    class: "genericButton bgGradient w-50 ms-1",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.accept && _ctx.accept(...args)))
                  }, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "app_accetta",
                      text: "Accetta",
                      class: "fs_14 white"
                    })
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}