
import { Options, Vue } from 'vue-class-component';
import { FriendRequestClient } from '@/services/Services';
import ChatComponent from '@/components/chat/chatComponent.vue';
import ChatHeader from '../components/chatHeader.vue';
import store from '@/store';
import * as OM from '@/model';
import RequestChatSettingsModal from '../modals/requestChatSettingsModal.vue';
import * as Const from '@/const';
import ChatSettingsModal from '../modals/chatSettingsModal.vue';

@Options({
    components: {
        ChatComponent,
        ChatHeader
    }
})
export default class ChatRequest extends Vue {

    loading: boolean = true;
    chatIdentifier: string = "";
    chatVm: OM.FriendRequestRoomVm = null;

    created() {
        this.loading = true;
        this.chatIdentifier = this.$route.params.identifier.toString();
        FriendRequestClient.getFriendRequestVm(this.chatIdentifier)
        .then(x => {
            if(!x){
                this.$router.replace('/chats/' + this.chatIdentifier)
                return;
            }
            this.chatVm = x;
            this.loading = false
        })
        .finally();
    }

    deny() {
        store.state.globalSpinner++;
        FriendRequestClient.deleteFriendRequest(this.chatIdentifier)
        .then(x => {
            this.$router.back();
        })
        .finally(() => store.state.globalSpinner--);
    }

    accept() {
        store.state.globalSpinner++;
        FriendRequestClient.acceptFriendRequest(this.chatIdentifier)
        .then(x => {
            this.$router.replace('/chats/' + x);
        })
        .finally(() => store.state.globalSpinner--);
    }

    openDetail() {
        this.$router.push('/profile/' + this.chatVm.userIdentifier);
    }

    settingsModal() {
        if(this.chatVm.toUser == store.state.user.identifier){
            this.$opModal.show(ChatSettingsModal, {
                chatName: this.chatVm.name,
                userIdentifier: this.chatVm.userIdentifier,
                isGroupChat: this.chatVm.type == Const.ChatRoomType.ChatRoom
            })
        } else {
            this.$opModal.show(RequestChatSettingsModal, {
                chatName: this.chatVm.name,
                friendRequestIdentifier: this.chatVm.identifier
            })
        }
    }

}
